<template>
    <div class="main-content">
        <el-form ref="ossform" :model="form" label-width="200px"  :rules="rule" class="oss-form">
            <el-form-item label="是否开启阿里oss" prop="isOss">
                <el-switch v-model="form.isOss"></el-switch>
            </el-form-item>
            <el-form-item label="accessKeyId" prop="accessKeyId">
                <el-input v-model="form.accessKeyId"></el-input>
            </el-form-item>
            <el-form-item label="accessKeySecret" prop="accessKeySecret">
                <el-input v-model="form.accessKeySecret"></el-input>
            </el-form-item>
            <el-form-item label="Endpoint（地域节点）" prop="endpoint">
                <el-input placeholder="请输入Endpoint（地域节点）" v-model="form.endpoint">
                    <template slot="prepend">Http://</template>
                </el-input>
            </el-form-item>
            <el-form-item label="Bucket名称" prop="bucketname">
                <el-input v-model="form.bucketname"></el-input>
            </el-form-item>
            <el-form-item label="Bucket 域名" prop="ossUrl">
                <el-input placeholder="请输入Bucket域名或用户自定义域名" v-model="form.ossUrl">
                    <template slot="prepend">Http://</template>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button  type="primary" @click="submitForm('ossform')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "AliOssManager",
    data() {
        var checkUrl = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必须为正确的网址'));
            }
            let patter = new RegExp('(.*)?\\.aliyuncs\\.com');
            if (!patter.test(value)) {
                return callback(new Error('请输入正确的Endpoint（地域节点）或Bucket 域名'));
            }
            callback();
        }
        return {
            form: {
                isOss: false,
                accessKeyId: '',
                accessKeySecret: '',
                endpoint: '',
                bucketname: '',
                ossUrl: ''
            },
            rule: {
                accessKeyId: [
                    {required: true, message: '请输入accessKeyId', trigger: 'blur'},
                    {min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur'}
                ],
                accessKeySecret: [
                    {required: true, message: '请输入accessKeySecret', trigger: 'blur'},
                    {min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur'}
                ],
                endpoint: [
                    {required: true, message: '请输入endpoint', trigger: 'blur'},
                    {validator: checkUrl, trigger: 'blur'}
                ],
                bucketname: [
                    { required: true, message: '请输入Bucket名称', trigger: 'blur' },
                    {min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur'}
                ],
                ossUrl: [
                    {required: true, message: '请输入Bucket 域名或用户自定义域名', trigger: 'blur'},
                ]
            }
        }
    },
    created() {
        this.settings();
    },
    methods: {
        settings() {
            this.$http.asyncGet(this.$api.oss_setting, {}).then(res => {
                if (res.code) {
                    this.form = {
                        accessKeyId: res.data.access_key_id,
                        accessKeySecret: res.data.access_key_secret,
                        bucketname: res.data.bucketname,
                        endpoint: res.data.endpoint,
                        ossUrl: res.data.oss_url
                    };
                    if (res.data.is_oss) {
                        this.$set(this.form, 'isOss', true);
                    } else {
                        this.$set(this.form, 'isOss', false);
                    }
                }
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formData = new FormData();
                    formData.append('access_key_id', this.form.accessKeyId);
                    formData.append('access_key_secret', this.form.accessKeySecret);
                    formData.append('endpoint', this.form.endpoint);
                    formData.append('bucketname', this.form.bucketname);
                    formData.append('oss_url', this.form.ossUrl);
                    this.form.isOss ? formData.append('is_oss', 1) : formData.append('is_oss', 0);
                    this.$http.asyncPost(this.$api.oss_save, formData).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 2000,
                                onClose: () => {
                                    this.settings();
                                }
                            });
                        }
                    }).catch(err => {
                        this.$message.error('请求异常');
                        console.log(err);
                    });
                } else {
                    console.log('shibai');
                    return false;
                }
            });
        }

    }
}
</script>

<style scoped lang="scss">
.main-content {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    .oss-form {
        width: 580px;
    }
}

</style>